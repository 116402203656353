/* General styles */
.header-container {
  display: flex;
  flex-direction: column; /* Logo on top for mobile */
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

/* Common styles for links */
.nav-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.nav-links a .nav-icon {
  font-size: 20px;
}

.nav-links a.active {
  color: #ff4081;
}

.nav-links a:hover {
  color: #ff4081;
}

/* Mobile specific styles */
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Ensure it's on top */
}

/* Check In Button (Center & Style) */
.checkin-link {
  background-color: #ff4081;
  border-radius: 30px;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text */
  z-index: 10;
}

.checkin-link:hover {
  background-color: #e91e63;
}

/* Active state for the Check In button */
.checkin-link.active {
  background-color: #c2185b;
  color: #fff200; /* Different color for icon/text when active */
}

/* Responsive Styles */
@media (min-width: 769px) {
  .bottom-nav {
    display: none;
  }

  .header-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .header-container {
    justify-content: center;
  }

  .logo {
    display: flex;
  }

  .nav-links {
    flex-direction: row;
    justify-content: center;
  }

  .checkin-link {
     /* Center Check In button */
    width: fit-content; /* Adjust width automatically */
  }
}
