/* Footer container */
.details {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f1f1f1; /* Light grey background to distinguish footer */
    border-top: 2px solid #ddd; /* Optional border for separation */
    color: #333; /* Darker text for contrast */
}

/* Left side of the footer */
.footer-left {
    flex: 1;
    padding-right: 20px;
}

.footer-left h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.footer-left p {
    margin: 5px 0;
    color: #555;
}

/* Social icons styling */
.social-icons {
    margin-top: 15px;
}

.social-icons a {
    margin-right: 15px;
    color: #555; /* Default color of the icons */
    text-decoration: none;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #007bff; /* Change color on hover */
}

/* Right side of the footer */
.footer-right {
    flex: 1;
    text-align: right;
}

.footer-right h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
}

.footer-right iframe {
    border: none;
    width: 100%;
    height: 200px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .details {
        flex-direction: column;
        text-align: center;
    }

    .footer-right {
        text-align: center;
        margin-top: 20px;
    }

    .footer-left,
    .footer-right {
        flex: 1;
    }
}

/* Media queries for phones */
@media (max-width: 600px) {
    .details {
        padding: 10px; /* Reduce padding for smaller screens */
    }

    .footer-left,
    .footer-right {
        padding: 0; /* Remove extra padding */
        margin-bottom: 15px; /* Add spacing between sections */
    }

    .footer-left h3,
    .footer-right h3 {
        font-size: 1.2rem; /* Decrease font size for headings */
    }

    .footer-left p {
        font-size: 0.9rem; /* Decrease font size for paragraphs */
    }

    .social-icons {
        margin-top: 10px; /* Adjust margin for social icons */
    }

    .social-icons a {
        margin-right: 10px; /* Decrease margin between icons */
    }
}
