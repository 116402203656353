.doctors-section {
  padding: 20px;
  text-align: center;
}

.doctors-section h2 {
  font-size: 2.5rem;
  color: #333; /* Bright pink for the main heading */
  margin-bottom: 20px;
} 

.doctors-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.doctor-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  width: 250px; /* Width for larger screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  text-align: center; /* Center text within the card */
}

.doctor-card h3 {
  font-size: clamp(1rem, 2.5vw, 1.5rem); /* Dynamically adjust font size */
  color: #da1993; /* Darker pink for subheading */
  margin: 20px 0;
  white-space: normal; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: unset; /* Add ellipsis if the text is too long */
}

.doctor-card:hover {
  transform: translateY(-10px);
}

.doctor-image {
  width: 100%; /* Make sure the image takes full width */
  /*height : auto */ 
  /* setting height as auto would have been a better alternavie still to keep the height same */
  height: 330px; /* Keep the aspect ratio */
  object-fit: cover; /* Ensures images fill the container without distortion */
  border-radius: 10px;
}


.qualification {
  font-size: 1.1em;
  color: #777;
  margin-bottom: 5px; /* Reduced margin for better spacing */
}

p {
  font-size: 0.9em; /* Reduced font size for better fit */
  color: #555;
  margin-bottom: 10px; /* Added margin for better spacing */
}

/* Media queries for phone screens */
@media (max-width: 600px) {
  .doctors-list {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
  }

  .doctor-card {
    width: 90%; /* Adjust card width for smaller screens */
    margin-bottom: 20px; /* Add space between cards */
  }

  .doctor-image {
    width: 100%; /* Make sure the image takes full width */
    height: auto; /* Auto-adjust height to maintain aspect ratio */
    object-fit: contain; /* Ensure the entire image is visible without cropping */
    border-radius: 10px;
  }
  
  h2 {
    font-size: 1.5em; /* Decrease heading size */
  }

  h3 {
    font-size: 1.2em; /* Decrease subheading size */
  }

  .qualification {
    font-size: 0.9em; /* Decrease qualification size */
  }

  p {
    font-size: 0.7em; /* Further reduced font size for smaller screens */
  }
}