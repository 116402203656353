/* General Styles */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

header {
  background-color: #f4f4f4;
  padding: 20px;
}

h1 {
  color: #333;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
}

/* Responsive Design for Small Screens */
@media only screen and (max-width: 600px) {
  header {
    padding: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  footer {
    padding: 10px;
    font-size: 0.9rem;
  }

  .App {
    font-size: 0.9rem;
  }
}
