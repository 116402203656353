/* Main Section */
.why-choose-us-section {
  background-color: #f8f7fd; /* Light pink background */
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Headings */
h2 {
  font-size: 2.5rem;
  color: #ff1493; /* Bright pink for the main heading */
  margin-bottom: 20px;
}

h3 {
  font-size: 1.8rem;
  color: #da1993; /* Darker pink for subheading */
  margin: 20px 0;
}

/* Paragraph */
p {
  font-size: 1.2rem;
  color: #333; /* Dark text for better readability */
  margin-bottom: 30px;
}

/* List Styling */
ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

ul li {
  font-size: 1.1rem;
  color: #1e1c92; /* Use pink for list items */
  padding: 10px 0; /* Space out list items */
  text-align: center; /* Align text to the left */
}

/* Mobile styles */
@media (max-width: 768px) {
  .why-choose-us-section {
    padding: 20px; /* Less padding on smaller screens */
  }

  h2 {
    font-size: 2rem; /* Smaller font size */
  }

  h3 {
    font-size: 1.5rem; /* Smaller font size */
  }

  p {
    font-size: 1rem; /* Smaller font size */
  }

  ul li {
    font-size: 1rem; /* Smaller font size */
  }
}
