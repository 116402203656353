/* Container for the services section */
.services-container {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.services-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    font-weight: bold;
}

/* Service card styling */
.service-card {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column; /* Changed to column for better layout */
    align-items: center;
}

.service-card:hover {
    transform: scale(1.03);
}

/* Service title styling */
.service-title {
    font-size: 1.3rem;
    font-weight: bold;
    color: #ff1493;
    margin-bottom: 10px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
}

/* Subcategory container styling */
.subcategory-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

/* Individual subcategory card styling */
.subcategory-item {
    background-color: #ffffff;
    border: 2px solid #ff1493;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s, transform 0.2s;
    flex: 1 1 220px; /* Flex grows to fit title, but maxes at 220px */
    max-width: 220px;
    min-width: 150px;
    overflow: hidden;
}

/* Ensure titles fit within the box */
.subcategory-item h4 {
    margin: 0;
    font-size: 1rem;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

/* Hover effect */
.subcategory-item:hover {
    background-color: #ffb6c1;
    transform: translateY(-5px);
}

/* Description styling */
.service-description {
    margin-top: 10px;
    font-size: 0.85rem;
    color: #555;
    white-space: normal;
    display: block;
}

/* Media Queries for mobile responsiveness */
@media (max-width: 600px) {
    .services-title {
        font-size: 2rem;
    }

    .service-card {
        padding: 15px;
    }

    .service-title {
        font-size: 1.2rem;
    }

    .subcategory-item {
        width: 100%;
        max-width: none;
        padding: 10px;
        font-size: 0.9rem;
    }

    .service-description {
        font-size: 0.8rem;
    }
}