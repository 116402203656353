.patient-testimony-container {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
}

h1 {
  font-size: 2.5rem;
  color: #ff1493; /* Pink color to match your theme */
  margin-bottom: 20px;
}

.video-carousel {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.video-item {
  display: inline-block;
  position: relative;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 2rem;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.prev {
  left: -50px;
}

.next {
  right: -50px;
}

iframe {
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure responsiveness */
  height: 400px; /* Height to resemble Shorts */
}

/* Medium screens (max-width: 768px) */
@media (max-width: 768px) {
  .carousel-btn {
    font-size: 1.5rem;
    padding: 8px;
  }

  .prev {
    left: -20px; /* Move arrows closer to the edges */
  }

  .next {
    right: -20px; /* Move arrows closer to the edges */
  }

  iframe {
    height: 300px; /* Adjust height for smaller screens */
  }
}

/* Small screens (max-width: 480px) */
@media (max-width: 480px) {
  .carousel-btn {
    font-size: 1.2rem;
    padding: 6px;
  }

  .prev {
    left: 10px; /* Ensure arrows are fully visible */
  }

  .next {
    right: 10px; /* Ensure arrows are fully visible */
  }

  iframe {
    height: 250px; /* Adjust height for small screens */
  }
}
