.google-form-iframe {
    width: 100%; /* Full width of the container */
    height: 100vh; /* Full viewport height to prevent internal scrolling */
    min-height: 700px; /* Minimum height for better visibility */
    max-width: 100%; /* Prevents overflow */
    border: none; /* Remove border */
    overflow: hidden; /* Prevent internal scroll */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .google-form-iframe {
      height: 1200px; /* Adjust height for medium screens */
    }
  }
  
  @media (max-width: 480px) {
    .google-form-iframe {
      height: 1000px; /* Adjust height for small screens */
    }
  }
  